import React from "react"
import styles from "./styles.module.css"
import PlanSideBar from "../../shared/planSideBar";
import { getIsPlanRecommendationWidgetOpened, refreshPlanRecommendationDetails, setIsPlanRecommendationWidgetOpened, viewPlan, viewPlanRecommendationDetails } from "../../services/customerInfo";
import { getUser } from "../../services/auth";
import { navigate } from "gatsby";
import { B2B2C_Tier, B2X, CloudB2C_ProductIDs, DesktopB2C_ProductIDs, System_Type } from "../../common/constants";
import DefaultLoader from "../../shared/loader";
import PlanRecommendationDetails from "../../shared/planRecommendationDetails";
import { getCustomAUMLimitFromOption_DesktopOnly, getFinalPlanRecommendationDetails } from "../../common/gFunctions";
import NewPlanProformaModal from "../../shared/newPlanProformaModal";
import { connect } from "react-redux";
const queryString = require('query-string');

class PlanRecommendationComponent extends React.Component {
    constructor(props) {
        super(props);
        const user = getUser();
        const token = user && user.access_token;

        var searchQuery = this.props.location.search;
        var search = queryString.parse(this.props.location.search, {arrayFormat: 'bracket'});
        if (!(search && search.cid)) {
            searchQuery = window.sessionStorage.getItem('desktopPlanRecommendation');
            if (searchQuery) {
                search = queryString.parse(searchQuery, {arrayFormat: 'bracket'});
            }
        }

        var b2x = undefined;
        var b2x_original = undefined;
        var planRecommendation = undefined;

        if (search && search.cid) {
            window.sessionStorage.setItem('desktopPlanRecommendation', searchQuery);

            var finalPlanRecommendation = getFinalPlanRecommendationDetails({
                B2X: search.t ? +search.t : undefined,
                B2C_Tier: +(search.i || 0),
                B2C_CustomAUMLimit: getCustomAUMLimitFromOption_DesktopOnly(search.ic),
                B2B2C_Tier: +(search.w || 0),
                B2B2C_CustomAUMLimit: getCustomAUMLimitFromOption_DesktopOnly(search.wc),
                B2B2C_ProductId: search.wp,
                B2B2C_NumPortfolios: search.wn || 2000
            }, false);
            b2x = finalPlanRecommendation.B2C && finalPlanRecommendation.B2B2C ? undefined : finalPlanRecommendation.B2X;
            b2x_original = b2x;
            planRecommendation = finalPlanRecommendation;
        }

        var isDesktop = (search && search.cid) || (this.props.location && this.props.location.state && this.props.location.state.isDesktop) ? true : false;

        this.state = {
            planRecommendation,
            isDesktop,
            b2x,
            b2x_original,
            token,
            isLoading: !isDesktop
        };

    }

    componentDidMount() {
        this.getPlan();
        if (this.state.isDesktop === false) {
            this.getPlanRecommendation();
        } else {
            if (this.state.planRecommendation) {
                this.getAndUpdateIsWidgetOpened();
            }
        }
    }

    updateIsWidgetOpened() {
        setIsPlanRecommendationWidgetOpened(this.state.token).then(({error,response}) => {

        })
    }

    getAndUpdateIsWidgetOpened() {
        getIsPlanRecommendationWidgetOpened(this.state.token).then(({response,error}) => {
            console.log("🚀 ~ PlanRecommendation ~ getIsPlanRecommendationWidgetOpened ~ response:", response)
            if (response) {
                this.setState({isWidgetPreviouslyOpened: response});
            }

            return this.updateIsWidgetOpened();
        })
    }

    getPlan = () => {
        viewPlan(this.state.token).then(({response,error}) => {
            if (error.status>=400) {
                console.log('ERROR',error);
            } else {
                this.setState({existingPlan: response, isDesktop: response.SystemType === System_Type.DESKTOP});
                console.log("🚀 ~ PlanRecommendation ~ viewPlan ~ response:", response)
            }
        })
    }

    getPlanRecommendation = () => {
        viewPlanRecommendationDetails(this.state.token).then(({response,error}) => {
            if (error.status>=400) {
                console.log('ERROR',error);
                if (error.stack && error.stack.Message && error.stack.Message.indexOf('legacy') > -1) {
                    navigate('/account/plan')
                }
            } else {
                this.setPlanRecommendationAfterCompleteForCloud(response);

                this.getAndUpdateIsWidgetOpened();

                if (!response) {
                    this.onRefresh();
                }
            }
        })
    }

    setPlanRecommendationAfterCompleteForCloud = (response) => {
        var finalPlanRecommendation = undefined, b2x = undefined;
        if (response) {
            finalPlanRecommendation = getFinalPlanRecommendationDetails(response, true);
            if (finalPlanRecommendation) {
                b2x = finalPlanRecommendation.B2C && finalPlanRecommendation.B2B2C ? undefined : finalPlanRecommendation.B2X;
            }
        }

        this.setState({planRecommendation: finalPlanRecommendation, b2x, b2x_original: b2x, isLoading: false});
    }

    onRefresh = () => {
        refreshPlanRecommendationDetails(this.state.token).then(({response,error}) => {
            if (error.status>=400) {
                console.log('ERROR',error);
            } else {
                this.setState({isLoading: true, planRecommendation: undefined});
                this.checkIfPlanRecommendationIsDone();
            }
        })
    }

    checkIfPlanRecommendationIsDone = () => {
        viewPlanRecommendationDetails(this.state.token).then(({response,error}) => {
            if (error.status>=400) {
                console.log('ERROR',error);
            } else {
                if (response && response.IsComplete) {
                    this.setPlanRecommendationAfterCompleteForCloud(response);
                } else {
                    setTimeout(this.checkIfPlanRecommendationIsDone, 2000);
                }
            }
        })
    }

    onContinuePurchase = (productId, customAUMLimit) => {
        this.props.clearSelectedProduct && this.props.clearSelectedProduct();
        console.log("🚀 ~ PlanRecommendation ~ this.state.planRecommendation:", this.state.planRecommendation)
        navigate('/account/order/product', {state: {productId, customAUMLimit, b2x: this.state.b2x, b2b2cTier: this.state.b2x === B2X.B2C ? undefined : B2B2C_Tier.PLATINUM, isFromPlanRecommendation: true}});
    }

    onRequestProforma = () => {
        this.setState({
            isProformaWidgetOpen: true
        })
        // navigate('/account/new-plan/proforma',{state: {planRecommendation: this.state.planRecommendation, b2x: this.state.b2x}});
    }

    render() {
        const { planRecommendation, b2x, isDesktop, isProformaWidgetOpen, existingPlan } = this.state;
        console.log("🚀 ~ PlanRecommendation ~ render ~ planRecommendation:", planRecommendation)
        var productId = -999;
        var customAUMLimit = -999;
        var canPurchaseOnline = false;
        if (this.state.planRecommendation) {
            productId = (this.state.b2x === B2X.B2C ? this.state.planRecommendation.B2C.ProductId : this.state.planRecommendation.B2B2C.ProductId);
            customAUMLimit = (this.state.b2x === B2X.B2C ? this.state.planRecommendation.B2C.CustomAUMLimit : this.state.planRecommendation.B2B2C.CustomAUMLimit);
            canPurchaseOnline = this.state.b2x === B2X.B2C && [CloudB2C_ProductIDs.LITE, CloudB2C_ProductIDs.PLUS, CloudB2C_ProductIDs.HNI, DesktopB2C_ProductIDs.DESKTOP_LITE, DesktopB2C_ProductIDs.DESKTOP_PLUS, DesktopB2C_ProductIDs.DESKTOP_SINGLEFAMILY].includes(productId);
            if (this.state.isWidgetPreviouslyOpened && !canPurchaseOnline && customAUMLimit <= (100*100*100000)) {
                canPurchaseOnline = true;
            }
        }
        const renewalEligible = existingPlan && existingPlan.RenewalEligible === true;
        return (
        <>
            <div className={styles.plan_section}>
                <div className="container-fluid">
                    <div className="row plan-grey-background">
                    <PlanSideBar isTemp={this.state.isTemp} page={"Plan"}/>
                        <div className={["col-lg-10", "col-md-12", "right_section"].join(" ")}>
                            <div className={[styles.mpr_plan_section,"mpr_plan_section","p-lg-5","p-md-3"].join(' ')}>
                                {
                                    b2x || !planRecommendation ?
                                    <div className={["mpr_c_plan_title", "mt-5", "pb-4"].join(" ")}>
                                        <h3>Renew MProfit by switching to a new plan</h3>
                                    </div>
                                    :
                                    <div className={[styles.choiceTitle, "mt-5"].join(" ")}>
                                        How do you use MProfit?
                                    </div>
                                }
                                {
                                    planRecommendation ?
                                    (
                                        b2x ?
                                        <>
                                            <PlanRecommendationDetails planRecommendation={planRecommendation} existingPlan={this.state.existingPlan} isB2C={b2x === B2X.B2C} isDesktop={isDesktop}/>
                                            <div className={[styles.buttonsContainer].join(' ')}>
                                                {
                                                    renewalEligible &&
                                                    (
                                                        canPurchaseOnline ?
                                                        <button id="refreshBtn" className={["btn", "btn-primary", "btn-sm", "rounded"].join(" ")} onClick={() => this.onContinuePurchase(productId, customAUMLimit)}>Subscribe Now</button>
                                                        :
                                                        <button id="refreshBtn" className={["btn", "btn-primary", "btn-sm", "rounded"].join(" ")} onClick={this.onRequestProforma}>Request Proforma</button>
                                                    )
                                                }
                                                {
                                                    !isDesktop &&
                                                    <button id="refreshBtn" className={["btn", "btn-primary", "btn-sm", "rounded", styles.recalculateBtn].join(" ")} onClick={this.onRefresh}>Recalculate my MProfit investment value</button>
                                                }
                                            </div>
                                        </>
                                        :
                                        <>
                                        <div className={["container",styles.contentContainer].join(" ")}>
                                            <div className={["row", "justify-content-md-center", styles.choiceCard].join(" ")} onClick={() => {this.setState({b2x: B2X.B2C})}}>
                                                <span className={[styles.choiceCardTitle].join(' ')}>As an Investor</span>
                                            </div>
                                            <div className={["row", "justify-content-md-center", styles.choiceCard].join(" ")} onClick={() => {this.setState({b2x: B2X.B2B2C})}}>
                                                <span className={[styles.choiceCardTitle].join(' ')}>As a Wealth Professional</span>
                                            </div>
                                        </div>
                                        </>
                                    )
                                    :
                                    (
                                        !isDesktop ?
                                        <div>
                                            <button id="calculateBtn" className={["btn", "btn-primary", "btn-sm", "rounded"].join(" ")} onClick={this.onRefresh}>Refresh</button>
                                        </div>
                                        :
                                        <div className="mpr_cr_plan_info">
                                            <div className={["card", "mpr-card-shadow", styles.cardContainer, "mb-5"].join(" ")}>
                                                <span style={{marginBottom: '10px'}}>To view your new recommended plan:</span>
                                                <ol style={{paddingLeft: '30px'}}>
                                                    <li>Launch the MProfit Desktop app</li>
                                                    <li>
                                                        In the top navigation of the app, click "Renew MProfit".
                                                    </li>
                                                    <li>
                                                        Follow the next steps on the screen so that the MProfit system can auto-recommend a new plan for you, as per your requirements.
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    isProformaWidgetOpen &&
                                    <NewPlanProformaModal planRecommendation={this.state.planRecommendation} isB2C={b2x === B2X.B2C} isDesktop={isDesktop} toggleModal={() => {this.setState({isProformaWidgetOpen: false})}}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <DefaultLoader isLoad={this.state.isLoading} isFull={true}/>
            </div>
        </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        clearSelectedProduct: (selectedProduct) => dispatch({type: 'SET_SELECTED_PRODUCT', data: undefined}),
    }
}

const PlanRecommendation = connect(
    mapStateToProps,
    mapDispatchToProps
)(PlanRecommendationComponent)  

export default PlanRecommendation