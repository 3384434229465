import React, { useState } from "react"
import styles from "./styles.module.css"
import FormField from "../formField";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { requestPlanRecommendationProforma } from "../../services/customerInfo";
import { getUser } from "../../services/auth";
import AlertModal from "../../shared/alertModal";


const NewPlanProformaModal = ({toggleModal = () => {}, planRecommendation = {}, isB2C, isDesktop}) => {
  return (
    <Modal isOpen={true} toggle={toggleModal} className={['new-plan-proforma-modal',styles.modal].join(" ")}>
      <ModalHeader className={styles.modal_header} toggle={toggleModal}>Request Proforma</ModalHeader>
      <ModalBody>
        <NewPlanProforma {...{planRecommendation, isB2C, isDesktop}} toggleModal={toggleModal}/>
      </ModalBody>
    </Modal>
  )
}

const NewPlanProforma = ({ planRecommendation = {}, isB2C, isDesktop, toggleModal }) => {
  const [alertMessage, setAlertMessage] = useState(undefined);

  const onSubmit = () => {
    const user = getUser();
    const token = user && user.access_token;

    var selectedPlan = isB2C ? planRecommendation.B2C : planRecommendation.B2B2C;
    requestPlanRecommendationProforma(token, {
      ProductId: selectedPlan.ProductId,
      CustomAUMLimit: selectedPlan.CustomAUMLimit,
      NumPortsAllowed: selectedPlan.NumPortsAllowed,
      Note: "Request proforma for plan " + (selectedPlan.ProductName)
    }).then(({error, response}) => {
      if (error.status < 400) {
        setAlertMessage(response);
      } else {
        setAlertMessage({
          IsSuccess: false,
          FormTitle: 'Error submitting request'
        });
      }
    })
  }

  const closeAlertModal = () => {
    if (alertMessage.IsSuccess) {
      toggleModal();
    } else {
      setAlertMessage(undefined);
    }
  };

  return (
    <>
      <div className="mpr_cr_plan_info">
        <div className={["card", "mpr-card-shadow", styles.cardContainer, "mb-5"].join(" ")}>
          {
            isB2C &&
            <>
            <div className="row">
              <FormField fieldName="ProductName" label="Plan" sectionName={'B2C'} sectionValue={planRecommendation} handleInputChange={()=>{}} perLine={1} disabled={true} disabledBlack={true}/>
            </div>
            </>
          }
          {
            !isB2C &&
            <>
            <div className="row">
              <FormField fieldName="ProductName" label="Plan" sectionName={'B2B2C'} sectionValue={planRecommendation} handleInputChange={()=>{}} perLine={1} disabled={true} disabledBlack={true}/>
            </div>
            </>
          }
        </div>
        <div className={[styles.buttonsContainer].join(' ')}>
          <button id="refreshBtn" className={["btn", "btn-primary", "btn-sm", "rounded"].join(" ")} onClick={onSubmit}>Submit request to MProfit team</button>
        </div>
      </div>
      {
        alertMessage &&
        <AlertModal isOpen={true} toggle={closeAlertModal} headerText={alertMessage.FormTitle} bodyText={alertMessage.ErrorMessage ? [alertMessage.ErrorMessage] : []} option2={{text:'Okay',onClick:closeAlertModal}}/>
      }
    </>
  )
}

export default NewPlanProformaModal