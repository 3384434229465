import { B2C_Tier } from "../../common/constants";
import { getAUMInCroresWithoutDecimals } from "../../common/gFunctions";

const AUMTooltip = ['Please note that investment value is the total current value (and not amount invested) of all your investments across all your portfolios and it does not include any liabilities.'];

const CASAPITooltip = ["Additional API refreshes available for purchase (Rs. 1,000 per 100 refreshes)", "Please note the MF CAS refreshes are done PAN-wise"]

const getOtherFeaturesLine = (existingPlanName) => {
    existingPlanName = existingPlanName ? existingPlanName + ' ' : '';

    return 'Other features & reports available in your current plan ' + existingPlanName + '(excluding below)';
}

const NumPortsTooltip =  [
    "MProfit counts each Investment and F&O portfolio as a separate portfolio. For example, if A. Mehta maintains the following 2 portfolios in MProfit:",
    "",
    "A. Mehta - INV",
    "A. Mehta - F&O",
    "",
    "Even though A. Mehta is only one person, he is maintaining 2 separate portfolios, making his current portfolio count 2."
]

const NumPortsTooltipDesktop =  [
    "MProfit counts each Investment, F&O and Accounts portfolio as a separate portfolio. For example, if A. Mehta maintains the following 3 portfolios in MProfit:",
    "",
    "A. Mehta - PMS",
    "A. Mehta - Accounts",
    "A. Mehta - F&O",
    "",
    "Even though A. Mehta is only one person, he is maintaining 3 separate portfolios, making his current portfolio count 3."
]

export const getB2CIncludedFeatures = (b2cTier, customAUMLimit, isDesktop, existingPlanName) => {
    if (isDesktop) return getB2CIncludedFeaturesDesktop(b2cTier, customAUMLimit, existingPlanName);

    switch (b2cTier) {
        default:
            return undefined;
        case B2C_Tier.LITE:
            return [
                {text:'Track total investments up to ₹ 1 Crore across portfolios', tooltip: AUMTooltip},
                {text: '5 free CAS API refreshes per month', tooltip: CASAPITooltip},
                getOtherFeaturesLine(existingPlanName)
            ];
        case B2C_Tier.PLUS:
            return [
                {text:'Track total investments up to ₹ 5 Crore across portfolios', tooltip: AUMTooltip},
                {text: '10 free CAS API refreshes per month', tooltip: CASAPITooltip},
                'Reconciliation feature',
                getOtherFeaturesLine(existingPlanName)
            ];
        case B2C_Tier.HNI:
            return [
                {text:'Track total investments up to ₹ 25 Crore across portfolios', tooltip: AUMTooltip},
                '2 web + mobile user logins',
                {text: '20 free CAS API refreshes per month', tooltip: CASAPITooltip},
                'Period-wise XIRR insights',
                'Benchmark XIRR comparison vs. Nifty50 TRI',
                'Sector-wise & market cap-wise allocation',
                'Equity Exposure across Stocks, MFs and ETFs ',
                'PMS cashflow tracking',
                getOtherFeaturesLine(existingPlanName)
            ];
        case B2C_Tier.FAMILYOFFICE:
            return [
                customAUMLimit > 0 ? {text:'Track total investments up to ₹ ' + getAUMInCroresWithoutDecimals(customAUMLimit) + ' Crore across portfolios', tooltip: AUMTooltip} : 'No Investment Value Limit',
                '4 web + mobile user logins',
                'Dedicated Relationship Manager',
                {text: '40 free CAS API refreshes per month', tooltip: CASAPITooltip},
                'Period-wise XIRR insights',
                'Benchmark XIRR comparison vs. Nifty50 TRI',
                'Sector-wise & market cap-wise allocation',
                'Equity Exposure across Stocks, MFs and ETFs ',
                'PMS cashflow tracking',
                getOtherFeaturesLine(existingPlanName)
            ];
    }
}

const getB2CIncludedFeaturesDesktop = (b2cTier, customAUMLimit, existingPlanName) => {
    switch (b2cTier) {
        default:
            return undefined;
        case B2C_Tier.LITE:
        case B2C_Tier.DESKTOP_LITE:
            return [
                {text:'Track total investments up to ₹ 1 Crore across portfolios', tooltip: AUMTooltip},
                getOtherFeaturesLine(existingPlanName)
            ];
        case B2C_Tier.PLUS:
        case B2C_Tier.DESKTOP_PLUS:
            return [
                {text:'Track total investments up to ₹ 5 Crore across portfolios', tooltip: AUMTooltip},
                'View your portfolios, reports & analytics on MProfit Cloud (via sync)',
                getOtherFeaturesLine(existingPlanName)
            ];
        case B2C_Tier.DESKTOP_HNI:
            return [
                {text:'Track total investments up to ₹ 25 Crore across portfolios', tooltip: AUMTooltip},
                '2 Desktop user licenses + 1 Cloud view login on web & mobile',
                'Period-wise XIRR insights',
                'Benchmark XIRR comparison vs. Nifty50 TRI',
                'Sector-wise & market cap-wise allocation',
                'Equity Exposure across Stocks, MFs and ETFs',
                'Accounting Module',
                getOtherFeaturesLine(existingPlanName)
            ];
        case B2C_Tier.DESKTOP_MULTIFAMILY:
            return [
                customAUMLimit > 0 ? {text:'Track total investments up to ₹ ' + getAUMInCroresWithoutDecimals(customAUMLimit) + ' Crore across portfolios', tooltip: AUMTooltip} : 'No Investment Value Limit',
                '4 Desktop user licenses + 1 Cloud view login on web & mobile',
                'Dedicated Relationship Manager',
                'Period-wise XIRR insights',
                'Benchmark XIRR comparison vs. Nifty50 TRI',
                'Sector-wise & market cap-wise allocation',
                'Equity Exposure across Stocks, MFs and ETFs',
                'Accounting Module',
                getOtherFeaturesLine(existingPlanName)
            ];
    }
}


export const getB2CNotIncludedFeatures = (b2cTier, customAUMLimit, isDesktop) => {
    if (isDesktop) return getB2CNotIncludedFeaturesDesktop(b2cTier, customAUMLimit);

    switch (b2cTier) {
        default:
            return undefined;
        case B2C_Tier.LITE:
            return [
                {text:'Tracking total investments higher than ₹ 1 Crore across portfolios (upgrade available)', tooltip: AUMTooltip},
                'F&O tracking & P/L',
                'Reconciliation feature',
                'Benchmark XIRR comparison feature',
                'PMS cashflow tracking'
            ];
        case B2C_Tier.PLUS:
            return [
                {text:'Tracking total investments higher than ₹ 5 Crore across portfolios (upgrade available)', tooltip: AUMTooltip},
                'Benchmark XIRR comparison feature',
                'PMS cashflow tracking'
            ];
        case B2C_Tier.HNI:
            return [
                {text:'Tracking total investments higher than ₹ 25 Crore across portfolios (upgrade available)', tooltip: AUMTooltip},
            ];
        case B2C_Tier.FAMILYOFFICE:
            if (customAUMLimit > 0) {
                return [
                    {text:'Tracking total investments higher than ₹ ' + getAUMInCroresWithoutDecimals(customAUMLimit) + ' Crore across portfolios (upgrade available)', tooltip: AUMTooltip},
                ]
            } else {
                return undefined;
            }
    }
}

const getB2CNotIncludedFeaturesDesktop = (b2cTier, customAUMLimit) => {
    switch (b2cTier) {
        default:
            return undefined;
        case B2C_Tier.LITE:
        case B2C_Tier.DESKTOP_LITE:
            return [
                {text:'Tracking total investments higher than ₹ 1 Crore across portfolios (upgrade available)', tooltip: AUMTooltip},
                'F&O tracking & P/L',
                'Benchmark XIRR comparison feature'
            ];
        case B2C_Tier.PLUS:
        case B2C_Tier.DESKTOP_PLUS:
            return [
                {text:'Tracking total investments higher than ₹ 5 Crore across portfolios (upgrade available)', tooltip: AUMTooltip},
                'Benchmark XIRR comparison feature'
            ];
        case B2C_Tier.DESKTOP_HNI:
            return [
                {text:'Tracking total investments higher than ₹ 25 Crore across portfolios (upgrade available)', tooltip: AUMTooltip},
            ];
        case B2C_Tier.DESKTOP_MULTIFAMILY:
            if (customAUMLimit > 0) {
                return [
                    {text:'Tracking total investments higher than ₹ ' + getAUMInCroresWithoutDecimals(customAUMLimit) + ' Crore across portfolios (upgrade available)', tooltip: AUMTooltip},
                ]
            } else {
                return undefined;
            }
    }
}

export const getB2B2CIncludedFeatures = (numPortsAllowed, customAUMLimit, isDesktop, existingPlanName) => {
    if (isDesktop) return getB2B2CIncludedFeaturesDesktop(numPortsAllowed, customAUMLimit, existingPlanName);
    
    return [
        customAUMLimit > 0 ? {text:'Track total investments up to ₹ ' + getAUMInCroresWithoutDecimals(customAUMLimit) + ' Crore across client portfolios', tooltip: AUMTooltip} : 'Contact MProfit with regards to the investment value limit',
        '3 web + mobile user logins',
        {text: `Manage up to ${numPortsAllowed} client portfolios`, tooltip: NumPortsTooltip},
        'Dedicated Relationship Manager',
        'Share MProfit web + mobile app access with your clients',
        'Add your logo in client web & mobile logins and reports',
        {text: '20 free CAS API refreshes per month', tooltip: CASAPITooltip},
        'Period-wise XIRR insights',
        'Benchmark XIRR comparison vs. Nifty50 TRI',
        'Sector-wise & market cap-wise allocation',
        'Equity Exposure across Stocks, MFs and ETFs',
        'PMS cashflow tracking',
        getOtherFeaturesLine(existingPlanName)
    ];
}

export const getB2B2CIncludedFeaturesDesktop = (numPortsAllowed, customAUMLimit, existingPlanName) => {
    return [
        customAUMLimit > 0 ? {text:'Track total investments up to ₹ ' + getAUMInCroresWithoutDecimals(customAUMLimit) + ' Crore across client portfolios', tooltip: AUMTooltip} : 'Contact MProfit with regards to the investment value limit',
        '3 Desktop user licenses + 1 Cloud view login on web & mobile',
        {text: `Manage up to ${numPortsAllowed} client portfolios`, tooltip: NumPortsTooltipDesktop},
        'Dedicated Relationship Manager',
        'Share MProfit web + mobile app access with your clients',
        'Add your logo in client web & mobile logins and reports',
        'Period-wise XIRR insights',
        'Benchmark XIRR comparison vs. Nifty50 TRI',
        'Sector-wise & market cap-wise allocation',
        'Equity Exposure across Stocks, MFs and ETFs',
        getOtherFeaturesLine(existingPlanName)
    ];
}

export const getB2B2CNotIncludedFeatures = (customAUMLimit, isDesktop) => {
    if (isDesktop) return getB2B2CNotIncludedFeaturesDesktop(customAUMLimit);

    if (customAUMLimit > 0) {
        return [
            {text:'Tracking total investments higher than ₹ ' + getAUMInCroresWithoutDecimals(customAUMLimit) + ' Crore across client portfolios (upgrade available)', tooltip: AUMTooltip},
        ];
    } else {
        return undefined;
    }
}

export const getB2B2CNotIncludedFeaturesDesktop = (customAUMLimit) => {
    if (customAUMLimit > 0) {
        return [
            {text:'Tracking total investments higher than ₹ ' + getAUMInCroresWithoutDecimals(customAUMLimit) + ' Crore across client portfolios (upgrade available)', tooltip: AUMTooltip},
        ];
    } else {
        return undefined;
    }
}