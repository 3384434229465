import React from "react"

import styles from "./styles.module.css";
import {isMobileOnly,isIOS} from 'react-device-detect';
import PricingCard from "../pricingCard";
import { PricingCalculator } from "../pricingCalculator";
import { StaticQuery, navigate } from "gatsby";
import { Modal } from "reactstrap";

const query = graphql`
query priceChangePlansModalQuery{
  allContentJson {
    edges {
      node {
        investorsPriceChangePlansContent {
            products {
              tier
              title
              for
              price
              currency
              perText
              gstText
              featuresTitle
              features {
                order
                title
                tooltip
              }
            }
        }
        investorsPriceChangePlansContentDesktop {
          products {
            tier
            title
            for
            price
            currency
            perText
            gstText
            featuresTitle
            features {
              order
              title
              tooltip
            }
          }
      }
      }
    }
  }
}
`;

const PriceChangePlansModal = ({isOpen, onCloseModal, isWealth, isDesktop}) => (
    <StaticQuery
      query={query}
      render={queryData => {
        console.log(queryData, 'PriceChangePlansModal');

        const data = queryData.allContentJson.edges[0].node[isDesktop ? 'investorsPriceChangePlansContentDesktop' : 'investorsPriceChangePlansContent'];
        console.log("🚀 ~ isDesktop:", isDesktop)

        if (!data) return <></>;

        var isMobileFinal = false, isIOSFinal = false;

        if (typeof window !== "undefined") {
            isMobileFinal = isMobileOnly;
            isIOSFinal = isIOS;
        }
        
        return (
            <Modal isOpen={isOpen} modalClassName={styles.modalOuter} className={styles.modalDialog} contentClassName={styles.modalContainer}>
                <div className={[styles.closeBtnMobile].join(' ')} onClick={onCloseModal}><i className={"material-icons"}>close</i></div>
                <div className={[styles.closeBtn].join(' ')} onClick={onCloseModal}><i className={"material-icons"}>close</i></div>
                <div className={[styles.pricingCardsContainer].join(" ")}>
                    {
                        data.products.map((product, index) => {
                            if (product.price != 0) {
                                return <PricingCard product={product} isCollapsedInitial={isMobileFinal && index > 0} key={index} hideCTA={true} isWealth={isWealth} isRequestPricingDisabled={true}/>
                            } else {
                                return <></>;
                            }
                        })
                    }
                </div>
            </Modal>
        );
      }}
    />
)

export default PriceChangePlansModal
