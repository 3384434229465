import React, { useState } from "react"
import styles from "./styles.module.css"
import FormField from "../formField";
import { getB2B2CIncludedFeatures, getB2B2CNotIncludedFeaturesDesktop, getB2CIncludedFeatures, getB2CNotIncludedFeatures } from "./data";
import PriceChangePlansModal from "../../components/priceChangePlansModal";
import Tooltip from "../../shared/Tooltip";

const PlanRecommendationDetails = ({ planRecommendation = {}, isB2C, isDesktop, existingPlan }) => {
  const [isPricingCardModalOpen, setIsPricingCardModalOpen] = useState(false);

  console.log("🚀 ~ PlanRecommendationDetails ~ render ~ planRecommendation:", planRecommendation)

  const AUMDisplay = isB2C ? planRecommendation.B2C.AUMDisplay : planRecommendation.B2B2C.AUMDisplay;
  console.log("🚀 ~ PlanRecommendationDetails ~ AUMDisplay:", AUMDisplay)

  const togglePricingCardModal = () => {
    setIsPricingCardModalOpen(!isPricingCardModalOpen);
  }

  const includedFeatures = isB2C ? getB2CIncludedFeatures(planRecommendation.B2C.B2C_Tier, planRecommendation.B2C.CustomAUMLimit, isDesktop, existingPlan && existingPlan.ProductName) : getB2B2CIncludedFeatures(planRecommendation.B2B2C.NumPortsAllowed, planRecommendation.B2B2C.CustomAUMLimit, isDesktop, existingPlan && existingPlan.ProductName);
  const notIncludedFeatures = isB2C ? getB2CNotIncludedFeatures(planRecommendation.B2C.B2C_Tier, planRecommendation.B2C.CustomAUMLimit, isDesktop) : getB2B2CNotIncludedFeaturesDesktop(planRecommendation.B2B2C.CustomAUMLimit, isDesktop);

  return (
    <div className={styles.container}>
      {
        AUMDisplay &&
        <div className={styles.heading}>
          <span>Your total investment value currently tracked in MProfit: </span>
          <span className={styles.aum}>{AUMDisplay}</span>
        </div>
      }
      {
        isB2C && planRecommendation.B2C.HasFNO &&
        <div className={styles.heading}>
          You currently manage F&O portfolios
        </div>
      }
      {
        isB2C && planRecommendation.B2C.HasPremiumPack &&
        <div className={styles.heading}>
          You are currently subscribed to the Premium Pack
        </div>
      }
      {
        isB2C && planRecommendation.B2C.UsesPMSCashflowTracking &&
        <div className={styles.heading}>
          You currently use PMS Cashflow Tracking
        </div>
      }
      <div className={styles.boldHeading}>
        <div>
          {`Recommended Plan:`} 
        </div>
        <div className={styles.plan}>
          {'MProfit ' + (isB2C ? planRecommendation.B2C.ProductName : planRecommendation.B2B2C.ProductName)}
        </div>
      </div>
      {
        includedFeatures &&
        <>
        <div className={styles.boldHeading}>
          What's included?
        </div>
        <div>
          <ul className={styles.list}>
          {
            includedFeatures.map((x, index) => {
              return <li>
                <span>{x.text || x}</span>
                {
                  x.tooltip &&
                  <>
                    <span className="qcircle" id={'included-tooltip-'+index} data-toggle="tooltip" data-placement="right">?</span>
                    <Tooltip text={x.tooltip} target={'included-tooltip-'+index}/>
                  </>
                }
              </li>
            })
          }
          </ul>
        </div>
        </>
      }
      {
        notIncludedFeatures &&
        <>
        <div className={styles.boldHeading}>
          What's not included?
        </div>
        <div>
          <ul className={styles.list}>
          {
            notIncludedFeatures.map(x => {
              return <li>{x.text || x}</li>
            })
          }
          </ul>
        </div>
        </>
      }
      {
        isB2C &&
        <div>
          <span>To explore all our available plans, click </span>
          <span className={styles.textButton} onClick={togglePricingCardModal}>here</span>
        </div>
      }
      <PriceChangePlansModal isOpen={isPricingCardModalOpen} onCloseModal={togglePricingCardModal} isWealth={isB2C === false} isDesktop={isDesktop}/>
    </div>
  )
}

export default PlanRecommendationDetails